
















































































































































































































import { Vue, Component } from "vue-property-decorator";
import { UserStore, JWTStore, MetaStore } from "@/store/modules";
import { User } from "@/store/models/user";
import RejectedUsers from "@/views/Admin/Components/RejectedUsers.vue";
import { eventHub } from "@/utils/eventHub";
import { roleChecker } from "@/utils/jwt";

@Component({
  components: {
    RejectedUsers
  },
  metaInfo: {
    title: "Rejected User List",
    meta: [
      { name: "keyword", content: "Sqcc user list" },
      {
        name: "description",
        content: "List of users of SQCC",
        vmid: "userList"
      }
    ]
  }
})
export default class RejectedUser extends Vue {
  dialogVisible: boolean = false;
  innerVisible: boolean = false;
  loading: boolean = false;
  selectedIndex = -1;
  data: User = {} as User;
  activeName: string = "source_seed_producer";
  searchString: string = "";
  formVisible: any = {
    demandCompany: false,
    seedCompany: false,
    general: false,
    admin: false
  };
  demandCompany: string = "Demand Company";
  demandSupplyCompany: string = "Supply/Demand Company";

  selectedProvince: number | null = null;
  selectedDistrict: number | null = null;

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  get dataList() {
    return UserStore.Users;
  }

  get provinces() {
    return MetaStore.Province;
  }

  get districts() {
    let district = MetaStore.District;
    if (this.selectedProvince) {
      district = district.filter(x => x.provinceid == this.selectedProvince);
    } else {
      district = [];
    }
    return district;
  }

  get activeTab() {
    return this.activeName;
  }

  get role() {
    return JWTStore.role;
  }

  get RejectedSSPUsers() {
    return UserStore.RejectedSSPUsers;
  }

  get RejectedISPUsers() {
    return UserStore.RejectedISPUsers;
  }

  get RejectedISDUsers() {
    return UserStore.RejectedISDUsers;
  }

  get RejectedISCUsers() {
    return UserStore.RejectedISCUsers;
  }

  set activeTab(tab: string) {
    this.activeName = tab;
    this.$router
      .replace({
        name: "RejectedUsers",
        query: { activeTab: this.activeName }
      })
      .catch((reason: any) => {});
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.dialogVisible = false;
    } else {
      this.$message.error("Form  Invalid");
    }
  }

  changeDemandCompanyTabName(obj: string) {
    this.demandCompany = "Demand Company - (" + obj + ")";
    if (obj == undefined) {
      this.demandCompany = "Demand Company";
    }
  }

  changeSupplyDemandCompanyTabName(obj: string) {
    this.demandSupplyCompany = "Supply/Demand Company - (" + obj + ")";
    if (obj == undefined) {
      this.demandSupplyCompany = "Supply/Demand Company";
    }
  }

  private created() {
    UserStore.getRejectedSSPUserList();
    UserStore.getRejectedISPUserList();
    UserStore.getRejectedISDUserList();
    UserStore.getRejectedISCUserList();

    MetaStore.getAllProvince();
    MetaStore.getAllDistrict();

    eventHub.$on("changeDemandCompanyTabName", this.changeDemandCompanyTabName);
    eventHub.$on("changeSupplyDemandCompanyTabName", this.changeSupplyDemandCompanyTabName);
  }

  mounted() {
    if (this.$route.query.activeTab) {
      this.activeTab = String(this.$route.query.activeTab);
    } else if (roleChecker(["ISSMAdmin"])) {
      this.activeTab = "improved_seed_producer";
    }
  }

  async handlePrevNextClick(val: any, list: string, approved?: any) {
    this.loading = true;
    if (list == "source_seed_producer") {
      await UserStore.getRejectedSSPUserList({
        searchString: this.searchString,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
        page: val
      });
    } else if (list == "improved_seed_producer") {
      await UserStore.getRejectedISPUserList({
        searchString: this.searchString,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
        page: val
      });
    } else if (list == "improved_seed_distributor") {
      await UserStore.getRejectedISDUserList({
        searchString: this.searchString,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
        page: val
      });
    } else if (list == "improved_seed_consumer") {
      await UserStore.getRejectedISCUserList({
        searchString: this.searchString,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
        page: val
      });
    } else {
      console.log(list);
    }
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    this.loading = false;
  }

  searchText() {
    let query = {
      searchString: this.searchString,
      provinceId: this.selectedProvince,
      districtId: this.selectedDistrict,
      page: 1
    };
    UserStore.getRejectedSSPUserList(query);
    UserStore.getRejectedISPUserList(query);
    UserStore.getRejectedISDUserList(query);
    UserStore.getRejectedISCUserList(query);
  }

  clearFilter() {
    this.selectedProvince = null;
    this.selectedDistrict = null;
    this.searchString = "";
    UserStore.getRejectedSSPUserList();
    UserStore.getRejectedISPUserList();
    UserStore.getRejectedISDUserList();
    UserStore.getRejectedISCUserList();
  }

  changeProvince() {
    this.selectedDistrict = null;
  }

  clearProvince() {
    this.selectedProvince = null;
    this.selectedDistrict = null;
  }
}
